import HeadLine from "../../HeadLine";

// Images
import liveImage from "../../../assets/images/live.png";

// SVGS
import whatsappImage from "../../../assets/svg/whatsapp.svg";

export default function Pricing() {
  return (
    <section id="pricing" className="py-16 dark:bg-jacarta-900">
      <HeadLine
        text="Pricing"
        classes="font-display text-jacarta-700 pb-12 text-center text-3xl dark:text-white"
      />

      <div className="container">
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
          <PriceCard
            price="$125"
            discountPrice="$99"
            plan="Standard"
            month="1 Year Package"
            dotColor="bg-[#fff212] dark:bg-[#fff212]"
          />
          <PriceCard
            price="$225"
            discountPrice="$180"
            plan="Premium"
            month="3 Year Package"
            dotColor="bg-[#f35bc7] dark:bg-[#f35bc7]"
          />
          <PriceCard
            price="$350"
            discountPrice="$225"
            plan="Platinum"
            month="5 Year Package"
            dotColor="bg-[#8dd059] dark:bg-[#8dd059]"
          />
        </div>
      </div>
    </section>
  );
}

const PriceCard = ({ price, discountPrice, plan, month, dotColor }) => {
  return (
    <div className="service-item relative rounded-2.5xl border border-jacarta-100 bg-white p-10 pt-12 transition-shadow hover:shadow-xl dark:border-jacarta-400 dark:bg-jacarta-700">
      <div className="flex items-center justify-center gap-2 mb-6">
        <span className={`h-4 w-4 rounded-full ${dotColor}`}></span>
        <span className="font-display text-lg dark:text-jacarta-200">
          {plan}
        </span>
      </div>
      <div className="flex flex-col items-center mb-8">
        {/* <span className="font-display text-4xl dark:text-white">{price}</span> */}

        <span className="font-display text-2xl dark:text-white">
          <del style={{ color: "#888888" }}>{price}</del>
        </span>
        <span className="font-display text-3xl dark:text-white">
          {discountPrice}
        </span>

        <span className="dark:text-jacarta-400">{month}</span>
      </div>
      <div className="text-center">
        <p className="dark:text-jacarta-200 mb-2">IPTV Free Trial (24 Hours)</p>
        <p className="flex items-center justify-center dark:text-jacarta-200 mb-2">
          <img
            src={liveImage}
            alt="live image"
            style={{ width: "50px", marginTop: "-10px", marginLeft: "10px" }}
          />
          Live Channels 10,000+
        </p>
        <p className="dark:text-jacarta-200 mb-2">Movies 130,000+</p>
        <p className="dark:text-jacarta-200 mb-2">Series 5,000+</p>
        <p className="dark:text-jacarta-200 mb-2">Ultra 4k Format</p>
        {/* <p className="flex items-center justify-center dark:text-jacarta-200 mb-2">
          24*7 Sales Support{" "}
          <a
            href="https://wa.me/+14153388197"
            className="contant-whatsapp"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={whatsappImage} alt="WhatsApp Icon" />
          </a>
        </p> */}
      </div>
    </div>
  );
};
