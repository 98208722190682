import { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// Components
import Layout from "./layout";

// Pages
import Home from "./pages/home";
import Privacy from "./pages/privacy";

// SVGS
import whatsappImage from "./assets/svg/whatsapp.svg";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout>
        <Home />

        {/* <a
          href="https://wa.me/+14153388197"
          className="whatsapp_float"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={whatsappImage}
            alt="WhatsApp Icon"
            className="whatsapp-icon"
          />
        </a> */}
      </Layout>
    ),
  },
  {
    path: "/terms-and-conditions",
    element: (
      <Layout>
        <Privacy />
      </Layout>
    ),
  },
]);

function App() {
  useEffect(() => {
    // On page load or when changing themes, best to add inline in `head` to avoid FOUC
    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }

    // Whenever the user explicitly chooses light mode
    // localStorage.theme = "light";

    // Whenever the user explicitly chooses dark mode
    localStorage.theme = "dark";

    // Whenever the user explicitly chooses to respect the OS preference
    // localStorage.removeItem("theme");
  }, []);

  return (
    <RouterProvider router={router} />
    // <Layout>
    //   <Home />
    //   <Privacy />

    //   <a
    //     href="https://wa.me/+14153388197"
    //     className="whatsapp_float"
    //     target="_blank"
    //     rel="noopener noreferrer"
    //   >
    //     <img
    //       src={whatsappImage}
    //       alt="WhatsApp Icon"
    //       className="whatsapp-icon"
    //     />
    //   </a>
    // </Layout>
  );
}

export default App;
